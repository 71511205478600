import axios from 'axios';
import { ApiResponse, ApiResponseType } from '../apiResponse';
import { SignupFormState, SignupResponseError } from '../../views/components/user/state';

export const signup = async (
  formData: SignupFormState
): Promise<SignupResponseError | string | undefined> => {
  const { company_name, first_name, last_name, email, phone, reference } = formData;

  try {
    const formData = new FormData(); // Create a FormData object
    formData.append('company_name', company_name);
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('reference', reference);

    const response = await axios.post<ApiResponseType>(
      `${process.env.REACT_APP_API_URL}/signup`,
      formData
    );

    if (response.data.success) {
      return response?.data?.data;
    } else {
      throw new ApiResponse(response.data.data); // Throw an ApiResponse instance for error handling
    }
  } catch (error: any) {
    const errorMessage =
      error instanceof ApiResponse
        ? error.message
        : error?.response?.data || 'Something went wrong';

    return errorMessage;
  }
};
