import React, { useState } from 'react';
import CommonLayout from '../layout/CommonLayout';
import {
  Button,
  CircularProgress,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { SignupFormState } from './state';
import { t } from 'i18next';
import { signup } from '../../../api/user/signup';
import { useNavigate } from 'react-router';
import { termsAndConditionUrl, privacyPolicyUrl } from '../../../constants/constants';
import LazyImage from '../common/LazyLoad';
import { S3PublicUrl } from '../../../constants/constants';
import { Link } from 'react-router-dom';

const Signup = () => {
  const title = 'Signup';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<SignupFormState>({
    company_name: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    reference: '',
    error: '',
    agree_to_terms: false
  });

  const referenceOptions = ['Google', 'LinkedIn', 'Referral', 'Mail', 'Email', 'Other'];

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value, type, checked } = event.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSelectChange = (event: SelectChangeEvent<any>): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = (): boolean => {
    const { company_name, first_name, last_name, email, phone, reference, agree_to_terms } =
      formData;
    const newErrors: Record<string, string> = {};

    if (!company_name) {
      newErrors.company_name = t('user.companyNameRequired');
    }
    if (!first_name) {
      newErrors.first_name = t('error.field.firstname');
    }
    if (!last_name) {
      newErrors.last_name = t('error.field.lastname');
    }
    if (!email) {
      newErrors.email = t('error.field.email');
    } else if (email) {
      // Email pattern validation
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailPattern.test(email)) {
        newErrors.email = t('user.enterValidEmail');
      }
    }
    if (!phone) {
      newErrors.phone = t('error.field.phoneRequired');
    } else {
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(phone)) {
        newErrors.phone = t('error.field.phone');
      }
    }
    if (!reference) {
      newErrors.reference = t('error.field.reference');
    }
    if (!agree_to_terms) {
      newErrors.agree_to_terms = t('user.agreeToTermsRequired');
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async () => {
    const isFormValid = validateForm();
    if (isFormValid) {
      try {
        setLoading(true);

        const response: any = await signup(formData);

        if (response && response.id) {
          // No error, navigate to the AccountSelection component
          localStorage.setItem('isRegister', 'yes');
          navigate('/signup-success');
        } else {
          const newErrors: Record<string, string> = {};

          if (response.company_name) {
            newErrors.company_name = response.company_name[0];
          } else if (response.email) {
            newErrors.email = response.email[0];
          } else if (response.phone) {
            newErrors.phone = response.phone[0];
          } else if (response.first_name) {
            newErrors.first_name = response.first_name[0];
          } else if (response.last_name) {
            newErrors.last_name = response.last_name[0];
          } else if (response.agree_to_terms) {
            newErrors.agree_to_terms = response.agree_to_terms[0];
          } else if (response.reference) {
            newErrors.reference = response.reference[0];
          } else {
            setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
          }

          setErrors(newErrors);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <div className="pre-login-wrapper">
        <div className="pre-login-inner">
          <div className="left-col">
            <div className="left-inner">
              <div className="logo-wrap">
                <Link to="/">
                  <LazyImage
                    src={`${S3PublicUrl}3lines-logo.svg`}
                    alt={'logo'}
                    rootMargin="0px 0px 200px 0px"
                  />
                </Link>
              </div>

              <div className="signup-content">
                <h3>Sign up now for FREE in under a minute</h3>
                <p className="info-text">No credit card required</p>

                <div className="signup-form">
                  <form>
                    <div className="row-wrap margin-40">
                      <div className="form-group w-full">
                        <label className="form-label">Company name</label>
                        <TextField
                          error={!!errors.company_name}
                          id="company-name"
                          variant="outlined"
                          name="company_name"
                          value={formData.company_name}
                          onChange={handleInputChange}
                          className="form-control"
                          placeholder="Enter company name"
                          type="text"
                          helperText={errors.company_name}
                          inputProps={{
                            maxLength: 120
                          }}
                        />
                      </div>
                      <div className="form-group w-full">
                        <label className="form-label">First name</label>
                        <TextField
                          error={!!errors.first_name}
                          id="first-name"
                          variant="outlined"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleInputChange}
                          className="form-control"
                          placeholder="Enter your first name"
                          type="text"
                          helperText={errors.first_name}
                          inputProps={{
                            maxLength: 120
                          }}
                        />
                      </div>
                      <div className="form-group w-full">
                        <label className="form-label">Last name</label>
                        <TextField
                          error={!!errors.last_name}
                          id="last-name"
                          variant="outlined"
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleInputChange}
                          className="form-control"
                          placeholder="Enter your last name"
                          type="text"
                          helperText={errors.last_name}
                          inputProps={{
                            maxLength: 120
                          }}
                        />
                      </div>
                      <div className="form-group w-full">
                        <label className="form-label">Work Email</label>
                        <TextField
                          error={!!errors.email}
                          id="email"
                          variant="outlined"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          className="form-control"
                          placeholder="Enter your email"
                          type="email"
                          helperText={errors.email}
                          inputProps={{
                            maxLength: 120
                          }}
                        />
                      </div>
                      <div className="form-group w-full">
                        <label className="form-label">Phone Number</label>
                        <TextField
                          error={!!errors.phone}
                          id="phone"
                          variant="outlined"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          className="form-control"
                          placeholder="Enter your phone number"
                          type="tel"
                          helperText={errors.phone}
                          inputProps={{
                            maxLength: 15
                          }}
                        />
                      </div>
                      <div className="form-group w-full">
                        <label className="form-label">How did you hear about 3Lines?</label>
                        <FormControl variant="outlined" error={!!errors.reference} fullWidth>
                          <Select
                            labelId="how-heard-label"
                            id="how-heard"
                            name="reference"
                            value={formData.reference}
                            onChange={handleSelectChange}
                            label="How did you hear about 3Lines?"
                            className="custom-select"
                            MenuProps={{ className: 'custom-dropdown-menu' }}
                            displayEmpty
                            renderValue={(selected) => {
                              if (selected === '') {
                                return <p className="dropdown-placeholder">Select an option</p>;
                              }
                              return selected as string;
                            }}>
                            <MenuItem value="" disabled>
                              Select an option
                            </MenuItem>
                            {referenceOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                          {!!errors.reference && (
                            <Typography variant="caption" color="error">
                              {errors.reference}
                            </Typography>
                          )}
                        </FormControl>
                      </div>
                      <div className="form-group w-full">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="agree_to_terms"
                              checked={formData.agree_to_terms}
                              onChange={handleInputChange}
                            />
                          }
                          label={
                            <>
                              <span style={{ whiteSpace: 'nowrap' }}>
                                I agree to 3Lines{' '}
                                <a
                                  href={termsAndConditionUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue">
                                  Terms and Conditions
                                </a>
                              </span>
                              <span>
                                and{' '}
                                <a
                                  href={privacyPolicyUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue">
                                  Privacy Policy
                                </a>
                              </span>
                            </>
                          }
                        />
                        {!!errors.agree_to_terms && (
                          <Typography variant="caption" color="error">
                            {errors.agree_to_terms}
                          </Typography>
                        )}
                      </div>
                    </div>

                    <div className="inner-btn-wrap">
                      {loading ? (
                        <div className="no-record">
                          <CircularProgress />
                        </div>
                      ) : (
                        <Button className="btn primary-btn mb-40" onClick={handleFormSubmit}>
                          Create account
                        </Button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="right-image-col">
            <div className="right-inner">
              <div className="img-block">
                <LazyImage
                  src={`${S3PublicUrl}background-signup-1.png`}
                  alt={'Signup'}
                  rootMargin="0px 0px 200px 0px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default Signup;
