import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import MeetingLeftMenu from './MeetingLeftMenu';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  decryptData
} from '../../../../utils/common/user';
import { fetchMeetingAgendaQuestions } from '../../../../api/meeting/fetchMeetingAgendaQuestions';
import {
  MeetingNotesFormState,
  MeetingNotesResponseError,
  MeetingQuestionStates,
  MeetingTaskResponseError,
  QuestionState
} from './state';
import NoteAddDialog from './NoteAddDialog';
import { t } from 'i18next';
import { useNotificationContext } from '../../layout/NotificationContext';
import PaginationSettings from '../../settings/Pagination';
import { createNotes } from '../../../../api/notes-tasks/createNotes';
import {
  DropdownResponse,
  MeetingPaginationResponse,
  TasksState,
  UpdateState
} from '../../user/state';
import PreviousMeeting from './PreviousMeeting';
import TaskAddEditDialog from './TaskAddEditDialog';
import { createTasks } from '../../../../api/notes-tasks/createTasks';
import { TaskFormState } from '../../registers/state';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MeetingQuestions from './MeetingQuestions';
import MeetingRegulatoryUpdate from './MeetingRegulatoryUpdate';
import { fetchMeetingRegulatoryUpdates } from '../../../../api/meeting/fetchMeetingRegulatoryUpdates';
import MeetingOtherBusiness from './MeetingOtherBusiness';
import { fetchMinuteQuestion } from '../../../../api/meeting/fetchMinuteQuestion';
import { fetchPreviousMeetingOpenTasks } from '../../../../api/meeting/fetchPreviousMeetingOpenTasks';
import { fetchActiveUsers } from '../../../../api/common/fetchActiveUsers';

const MeetingReviewAgenda = () => {
  const user = useCurrentUserInfo();
  const module_type = 'meetings';
  const authToken = useAuthToken();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const selectedAccount = getSelectedAccountInfo();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_id = parseInt(sourceId!, 10);
  let meetingId = queryParams.get('meetingId');
  const encryptedMeetingId = meetingId; // Decrypt the ID
  meetingId = decryptData(meetingId, true);
  const record_id = parseInt(meetingId!, 10);
  let agendaId = queryParams.get('agendaId');
  agendaId = decryptData(agendaId, true);
  const agenda_id = parseInt(agendaId!, 10);
  const { moduleRecords } = useModuleContext();
  const { setNotificationSuccess } = useNotificationContext();
  const [mainCurrentPage, setMainCurrentPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [dropdownOptions, setDropdownOptions] = useState<{ [key: string]: string }>({});

  const mainPath = `/meetings/${encryptedSourceId}`;
  let mainTitle;
  const title = 'Review Meeting Agenda';
  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    mainTitle = foundRegister ? foundRegister?.name : '';
  }
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;

  let secondTitle;
  const unique_id = localStorage.getItem('meeting_unique_id');
  if (unique_id) {
    secondTitle = unique_id;
  }
  const secondpath = `/meeting/${encryptedMeetingId}?source=${encryptedSourceId}`;

  const [staticQuestion, setStaticQuestion] = useState<QuestionState | null>(null);
  const [openTasks, setOpenTasks] = useState<TasksState[]>([]);
  const [questionData, setQuestionData] = useState<MeetingQuestionStates>(
    {} as MeetingQuestionStates
  );
  const [questionId, setQuestionId] = useState<number>(0);
  const [taskId, setTaskId] = useState<number>(0);
  const [updateId, setUpdateId] = useState<number>(0);
  const [question, setQuestion] = useState<string>('');
  const [openNoteAddDialog, setOpenNoteAddDialog] = useState(false);
  const [files, setFiles] = useState<File[]>([]); // Store the selected files as an array
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [openInfo, setOpenInfo] = useState<{ [key: number]: boolean }>({});
  const [openRows, setOpenRows] = useState<{ [key: number]: boolean }>({});
  const [openTaskRows, setOpenTaskRows] = useState<{ [key: number]: boolean }>({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<MeetingNotesFormState>({
    subject: '',
    note: '',
    attachments: [],
    error: ''
  });
  const [ownerArray, setOwnerArray] = useState<DropdownResponse[]>([]);
  const [taskFormData, setTaskFormData] = useState<TaskFormState>({
    id: 0,
    task: '',
    details: '',
    owner: 0,
    date_due: '',
    is_complete: 0,
    date_complete: '',
    completed_notes: '',
    error: ''
  });
  const [openTaskAddEditDialog, setOpenTaskAddEditDialog] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [meetingRegulatoryUpdate, setMeetingRegulatoryUpdate] = useState<UpdateState[]>([]);

  const allowedExtensions = [
    'jpeg',
    'jpg',
    'png',
    'gif',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'pdf',
    'csv',
    'txt',
    'tx',
    'eml',
    'msg',
    'odt'
  ];

  const handleInfoToggle = (questionId: number) => {
    setOpenInfo((prevState) => ({
      ...prevState,
      [questionId]: !prevState[questionId]
    }));
  };

  const handleRowToggle = (rowId: number, type: string = 'note') => {
    if (type === 'note') {
      setOpenRows((prevState) => ({
        ...prevState,
        [rowId]: !prevState[rowId]
      }));
    } else {
      setOpenTaskRows((prevState) => ({
        ...prevState,
        [rowId]: !prevState[rowId]
      }));
    }
  };

  const toggleNoteAddDialog = (
    id: number = 0,
    question: string = '',
    type: string = 'question'
  ) => {
    setFormData({
      subject: '',
      note: '',
      attachments: [],
      error: ''
    });
    if (type == 'task') {
      setTaskId(id);
      setUpdateId(0);
      setQuestionId(0);
    } else if (type == 'update') {
      setUpdateId(id);
      setTaskId(0);
      setQuestionId(0);
    } else {
      setQuestionId(id);
      setTaskId(0);
      setUpdateId(0);
    }
    setQuestion(question);
    setOpenNoteAddDialog(!openNoteAddDialog);
    setErrors({});
    setSelectedFiles(null);
  };

  const toggleTaskAddEditDialog = (
    questionId: number = 0,
    question: string = '',
    task: TasksState = {} as TasksState
  ) => {
    setTaskFormData(task);
    setQuestionId(questionId);
    setQuestion(question);
    setOpenTaskAddEditDialog(!openTaskAddEditDialog);
    setErrors({});
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string = 'note'
  ) => {
    const { name, value } = event.target;

    if (type === 'note') {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    } else if (type === 'task') {
      setTaskFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputFiles = event.target.files;
    const newFiles = Array.from(inputFiles || []);

    for (const file of newFiles) {
      if (file instanceof File) {
        // Check if 'file' is an instance of File
        const fileName = file.name || ''; // Ensure fileName is defined
        const extension = (fileName.split('.').pop() || '').toLowerCase();
        if (!allowedExtensions.includes(extension)) {
          alert('Invalid file extension. Allowed extensions: ' + allowedExtensions.join(', '));
          // Clear the file input if necessary
          event.target.value = '';
          return;
        }
      }
    }
    // Create a new array for the selected files
    setSelectedFiles(inputFiles);
    setFiles(newFiles);
  };

  const removeSelectedFile = (index: number) => {
    if (selectedFiles) {
      const updatedFiles = Array.from(selectedFiles);
      updatedFiles.splice(index, 1);
      if (updatedFiles.length === 0) {
        setSelectedFiles(null);
      } else {
        // Create a new FileList based on the updatedFiles array
        const newSelectedFiles = new DataTransfer();
        updatedFiles.forEach((file) => {
          newSelectedFiles.items.add(file);
        });

        setSelectedFiles(newSelectedFiles.files);
      }
      setFiles(updatedFiles);
    }
  };

  const handleChangeUser = (event: SelectChangeEvent<string>) => {
    setTaskFormData((prevData) => ({ ...prevData, owner: parseInt(event.target.value) }));
  };

  const handleDateInputChange = (datetime: any, inputName: string) => {
    setTaskFormData((prevData) => ({
      ...prevData,
      [inputName]: datetime.format('YYYY-MM-DD')
    }));
  };

  const handleIsCompleteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTaskFormData((prevData) => ({
      ...prevData,
      is_complete: event.target.checked ? 1 : 0,
      date_complete: '',
      completed_notes: ''
    }));
    if (!event.target.checked) {
      setErrors({});
    }
  };

  const fetchQuestions = async () => {
    if (agendaId && authToken) {
      try {
        const response: MeetingPaginationResponse = (await fetchMeetingAgendaQuestions(
          agenda_id,
          record_id,
          1,
          authToken,
          currentPage,
          questionId
        )) as MeetingPaginationResponse;

        if (response) {
          if (response.questions.data.length > 0) {
            setQuestionData(response.questions.data[0]);
          }
          if (response.pageDropdownOptions) {
            // Parse the JSON string into a JavaScript object
            const parsedOptions = JSON.parse(response.pageDropdownOptions);
            // Check if the parsed options object is not empty
            setDropdownOptions(parsedOptions);
          }
          setTotalPages(response.questions.last_page);
        }
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    }
  };

  useEffect(() => {
    if (mainCurrentPage == 1) {
      getPreviousMeetingOpenTasks();
    }

    if (mainCurrentPage == 1 || mainCurrentPage == 4) {
      getStaticQuestion();
    } else if (mainCurrentPage == 2) {
      fetchQuestions();
    } else if (mainCurrentPage == 3) {
      fetchMeetingRegulatoryUpdate();
    }
  }, [mainCurrentPage, currentPage]);

  const fetchUsers = async () => {
    try {
      const response: DropdownResponse[] = await fetchActiveUsers(authToken, selectedAccount);
      if (response) {
        setOwnerArray(response);
      }
    } catch (error) {
      setErrors({});
      console.error('Error fetching templates:', error);
    }
  };

  useEffect(() => {
    if (openTaskAddEditDialog) {
      fetchUsers();
    } else {
      setOwnerArray([]);
    }
  }, [openTaskAddEditDialog]);

  const getStaticQuestion = async () => {
    try {
      const response: any = (await fetchMinuteQuestion(
        authToken,
        mainCurrentPage,
        record_id,
        1
      )) as any;

      if (response.id) {
        setStaticQuestion(response);
      } else {
        console.log('Error:', response);
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const getPreviousMeetingOpenTasks = async () => {
    try {
      const response: any = (await fetchPreviousMeetingOpenTasks(
        record_id,
        selectedAccount,
        agenda_id,
        authToken
      )) as any;

      if (Array.isArray(response)) {
        setOpenTasks(response);
      } else {
        console.log('Error:', response);
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const fetchMeetingRegulatoryUpdate = async () => {
    try {
      const response: any = (await fetchMeetingRegulatoryUpdates(
        record_id,
        selectedAccount,
        agenda_id,
        1,
        authToken
      )) as any;

      if (Array.isArray(response)) {
        setMeetingRegulatoryUpdate(response);
      } else {
        setMeetingRegulatoryUpdate([]);
        console.log('Error:', response);
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (mainCurrentPage == 3 && meetingRegulatoryUpdate.length == 0) {
      handleMainPageChange();
    }
  }, [meetingRegulatoryUpdate]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleMainPageChange = (type: string = 'next') => {
    if (type == 'prev') {
      if (mainCurrentPage == 4 && meetingRegulatoryUpdate.length == 0) {
        setMainCurrentPage((prev) => prev - 2);
      } else {
        setMainCurrentPage((prev) => prev - 1);
      }

      if (mainCurrentPage == 3 || (mainCurrentPage == 4 && meetingRegulatoryUpdate.length == 0)) {
        setCurrentPage((prev) => prev - 1);
      }
    } else if (mainCurrentPage !== 4) {
      if (mainCurrentPage == 2 && agenda_id == 4) {
        setMainCurrentPage((prev) => prev + 2);
      } else {
        setMainCurrentPage((prev) => prev + 1);
      }
    }

    if (type === 'next' && mainCurrentPage === 4) {
      navigate(`/meeting/${encryptedMeetingId}?source=${encryptedSourceId}`);
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleMouseEnter = (questionId: number) => {
    setQuestionId(questionId);
  };

  const validateForm = (type: string): boolean => {
    const newErrors: Record<string, string> = {};
    if (type === 'note') {
      if (!formData.subject) {
        newErrors.subject = t('error.field.subjectRequired');
      }
    } else {
      if (!taskFormData.task) {
        newErrors.task = t('error.field.task');
      }
      if (!taskFormData.owner) {
        newErrors.owner = t('error.field.ownerRequired');
      }

      if (!taskFormData.date_due) {
        newErrors.date_due = t('error.field.dueDate');
      }

      if (taskFormData.is_complete === 1) {
        if (!taskFormData.date_complete) {
          newErrors.date_complete = t('error.field.dateComplete');
        }
        if (!taskFormData.completed_notes) {
          newErrors.completed_notes = t('error.field.completeNotes');
        }
      }
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent, type: string = 'note') => {
    setLoading(true);
    event.preventDefault();
    const isFormValid = validateForm(type);
    if (!isFormValid) {
      setLoading(false);
      return;
    }
    if (type === 'note') {
      const requestData = {
        subject: formData.subject,
        note: formData.note,
        attachments: files
      };
      const response: MeetingNotesResponseError = (await createNotes(
        requestData,
        record_id,
        source_id,
        module_type,
        authToken,
        selectedAccount,
        0,
        questionId,
        1,
        taskId,
        updateId
      )) as MeetingNotesResponseError;
      if (!response?.id) {
        const newErrors: Record<string, string> = {};
        if (response.subject) {
          newErrors.subject = response.subject[0];
        } else if (response.attachments) {
          newErrors.attachments = response.attachments[0];
        } else {
          setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
        }
        setErrors(newErrors);
        setLoading(false);
      } else {
        const msg = t('success.field.noteAdded');
        toggleNoteAddDialog();
        localStorage.removeItem('meetingTasksCount');
        localStorage.removeItem('meetingNotesCount');
        setNotificationSuccess(msg);
        setLoading(false);
      }
    } else {
      const requestData = {
        id: taskFormData.id,
        task: taskFormData.task,
        module_id: taskFormData.module_id,
        details: taskFormData.details,
        owner: taskFormData.owner,
        date_due: taskFormData.date_due,
        is_complete: taskFormData.is_complete,
        date_complete: taskFormData.date_complete,
        completed_notes: taskFormData.completed_notes
      };

      const record_id = parseInt(meetingId!, 10);
      const task_id = taskFormData.id || 0;
      const response: MeetingTaskResponseError = (await createTasks(
        requestData,
        record_id,
        source_id,
        module_type,
        authToken,
        selectedAccount,
        task_id,
        questionId
      )) as MeetingTaskResponseError;
      if (!response?.id) {
        const newErrors: Record<string, string> = {};
        if (response.task) {
          newErrors.task = response.task[0];
        } else if (response.details) {
          newErrors.details = response.details[0];
        } else if (response.owner) {
          newErrors.owner = response.owner[0];
        } else if (response.date_due) {
          newErrors.date_due = response.date_due[0];
        } else if (response.date_complete) {
          newErrors.date_complete = response.date_complete[0];
        } else if (response.completed_notes) {
          newErrors.completed_notes = response.completed_notes[0];
        } else {
          setTaskFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
        }
        setErrors(newErrors);
        setLoading(false);
      } else {
        const msg = t('success.field.taskAdded');
        setNotificationSuccess(msg);
        toggleTaskAddEditDialog();
        setLoading(false);
      }
    }

    if (mainCurrentPage == 1) {
      getStaticQuestion();
      getPreviousMeetingOpenTasks();
    } else if (mainCurrentPage == 2) {
      fetchQuestions();
    } else if (mainCurrentPage == 3) {
      fetchMeetingRegulatoryUpdate();
    } else if (mainCurrentPage == 4) {
      getStaticQuestion();
    }
  };

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section settings-page-wrap">
        <div className="container-full">
          <CommonBreadcrumbs
            mainTitle={mainTitle}
            mainPath={mainPath}
            title={title}
            pageTitle={title}
            secondTitle={secondTitle}
            secondpath={secondpath}
          />

          <div className="record-outer-row settings-outer-row">
            <MeetingLeftMenu
              user={user}
              meetingId={meetingId || ''}
              sourceId={sourceId ? sourceId : ''}
              meetingIdEncrypted={encryptedMeetingId}
              sourceIdEncrypted={encryptedSourceId}
            />
            <div className="right-outer">
              <div className="right-inner">
                {mainCurrentPage == 1 ? (
                  <PreviousMeeting
                    type="review-agenda"
                    pathName={pathname}
                    question={staticQuestion}
                    openTasks={openTasks}
                    minutesFormData={[]}
                    isScribe={''}
                    openRows={openRows}
                    openTaskRows={openTaskRows}
                    handleNAChange={() => {}}
                    handleInputChange={handleInputChange}
                    handleMouseEnter={handleMouseEnter}
                    handleRowToggle={handleRowToggle}
                    toggleNoteAddDialog={toggleNoteAddDialog}
                    toggleTaskAddEditDialog={toggleTaskAddEditDialog}
                  />
                ) : mainCurrentPage == 2 ? (
                  <MeetingQuestions
                    type="review-agenda"
                    pathName={pathname}
                    questionData={questionData}
                    minutesFormData={[]}
                    isScribe={''}
                    openInfo={openInfo}
                    openRows={openRows}
                    openTaskRows={openTaskRows}
                    handleInfoToggle={handleInfoToggle}
                    handleInputChange={handleInputChange}
                    handleMouseEnter={handleMouseEnter}
                    handleNAChange={() => {}}
                    handleRowToggle={handleRowToggle}
                    toggleNoteAddDialog={toggleNoteAddDialog}
                    toggleTaskAddEditDialog={toggleTaskAddEditDialog}
                  />
                ) : mainCurrentPage == 3 ? (
                  <MeetingRegulatoryUpdate
                    type="review-agenda"
                    pathName={pathname}
                    meetingRegulatoryUpdate={meetingRegulatoryUpdate}
                    minutesFormData={[]}
                    isScribe={''}
                    openRows={openRows}
                    openTaskRows={openTaskRows}
                    handleNAChange={() => {}}
                    handleInputChange={handleInputChange}
                    handleMouseEnter={handleMouseEnter}
                    handleRowToggle={handleRowToggle}
                    toggleNoteAddDialog={toggleNoteAddDialog}
                    toggleTaskAddEditDialog={toggleTaskAddEditDialog}
                  />
                ) : mainCurrentPage == 4 ? (
                  <MeetingOtherBusiness
                    type="review-agenda"
                    pathName={pathname}
                    question={staticQuestion}
                    minutesFormData={[]}
                    isScribe={''}
                    openRows={openRows}
                    openTaskRows={openTaskRows}
                    handleNAChange={() => {}}
                    handleInputChange={handleInputChange}
                    handleMouseEnter={handleMouseEnter}
                    handleRowToggle={handleRowToggle}
                    toggleNoteAddDialog={toggleNoteAddDialog}
                    toggleTaskAddEditDialog={toggleTaskAddEditDialog}
                  />
                ) : null}

                {mainCurrentPage !== 2 ? (
                  <div className="pagination">
                    <p
                      className={`previous-btn ${mainCurrentPage == 1 ? 'disabled' : ''}`}
                      onClick={() => {
                        mainCurrentPage > 1 ? handleMainPageChange('prev') : '';
                      }}>
                      <ArrowBackIcon /> Back
                    </p>
                    <p className={`next-btn`} onClick={() => handleMainPageChange()}>
                      {mainCurrentPage !== 4 ? 'Next Page' : 'Save'}
                      <ArrowForwardIcon />
                    </p>
                  </div>
                ) : (
                  <>
                    {questionData && (
                      <PaginationSettings
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        isPagesShow={false}
                        prevButtonText="Back"
                        nextButtonText="Next Page"
                        isShowPagesDropdown={true}
                        mainCurrentPage={mainCurrentPage}
                        handleMainPageChange={handleMainPageChange}
                        dropdownOptions={dropdownOptions}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <NoteAddDialog
        question={question}
        formData={formData}
        errors={errors}
        selectedFiles={selectedFiles}
        openNoteAddDialog={openNoteAddDialog}
        toggleNoteAddDialog={toggleNoteAddDialog}
        handleInputChange={handleInputChange}
        handleFileChange={handleFileChange}
        removeSelectedFile={removeSelectedFile}
        handleSubmit={handleSubmit}
        loading={loading}
      />

      <TaskAddEditDialog
        question={question}
        formData={taskFormData}
        ownerArray={ownerArray}
        errors={errors}
        openTaskAddEditDialog={openTaskAddEditDialog}
        toggleTaskAddEditDialog={toggleTaskAddEditDialog}
        handleInputChange={handleInputChange}
        handleChangeUser={handleChangeUser}
        handleDateInputChange={handleDateInputChange}
        handleIsCompleteChange={handleIsCompleteChange}
        handleSubmit={handleSubmit}
        loading={loading}
      />
    </CommonLayout>
  );
};

export default MeetingReviewAgenda;
