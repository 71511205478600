import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { loginUser } from '../../../api/user/login';
import { LoginFormState } from './state';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { IconButton, InputAdornment, Alert, CircularProgress } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useForgotPasswordContext } from './ForgotPasswordContext';
import { useDispatch } from 'react-redux'; // Import useDispatch
import CloseIcon from '@mui/icons-material/Close';
import CommonLayout from '../layout/CommonLayout';
import {
  checkUserIsNewEntrant,
  decryptData,
  setSelectedAccountInfo
} from '../../../utils/common/user';
import { setAccounts } from '../../../store/account/accountSlice';
import { updateloginInfoAccount } from '../../../api/user/updateloginInfoAccount';
import LazyImage from '../common/LazyLoad';
import { S3PublicUrl } from '../../../constants/constants';

const Login: React.FC = () => {
  const title = 'Login';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Get the dispatch function
  const [showAlert, setShowAlert] = useState(true);
  const { forgotPasswordSuccess, setForgotPasswordSuccess } = useForgotPasswordContext();
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  let successAlertTimer: ReturnType<typeof setTimeout>;

  const [formData, setFormData] = useState<LoginFormState>({
    email: '',
    password: '',
    passwordError: '',
    error: '',
    emailError: ''
  });

  useEffect(() => {
    if (forgotPasswordSuccess) {
      setShowSuccessAlert(true);

      // Set a timer to close the alert after 5000 milliseconds (5 seconds)
      successAlertTimer = setTimeout(() => {
        setShowSuccessAlert(false);
        setForgotPasswordSuccess(false);
      }, 5000);
    }

    return () => {
      // Clear the timer when the component unmounts or when forgotPasswordSuccess changes
      clearTimeout(successAlertTimer);
    };
  }, [forgotPasswordSuccess, setForgotPasswordSuccess]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const validateForm = (): boolean => {
    const { email, password } = formData;

    let isValid = true;

    if (!email) {
      setFormData((prevData) => ({
        ...prevData,
        emailError: t('user.emailRequired')
      }));
      isValid = false;
    } else if (email) {
      // Email pattern validation
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailPattern.test(email)) {
        setFormData((prevData) => ({
          ...prevData,
          emailError: t('user.enterValidEmail')
        }));

        isValid = false;
      } else {
        setFormData((prevData) => ({
          ...prevData,
          emailError: ''
        }));

        isValid = true;
      }
    }

    if (!password) {
      setFormData((prevData) => ({
        ...prevData,
        passwordError: t('user.passwordRequired')
      }));
      isValid = false;
    } else {
      setFormData((prevData) => ({
        ...prevData,
        passwordError: ''
      }));
    }

    return isValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    setLoading(true);

    event.preventDefault();

    const isFormValid = validateForm();

    if (!isFormValid) {
      setLoading(false);
      return;
    }

    const errorMessage = await loginUser(dispatch, formData); // Pass the dispatch function

    if (errorMessage) {
      setLoading(false);
      setFormData((prevData) => ({
        ...prevData,
        emailError: '',
        passwordError: '',
        error: errorMessage
      }));
      setShowAlert(true);
    } else {
      setLoading(false);
      // No error, redirect to dashboard or AccountSelection component
      const userData = decryptData(localStorage.getItem('userData'))?.userInfo;
      const authToken = decryptData(localStorage.getItem('userData'))?.token;
      const storedRedirect = localStorage.getItem('redirectAfterLogin');

      if (userData?.userAccounts?.length == 1) {
        // if user has only single account access redirect to dashboard
        const userAccountData = userData?.user_access[0]?.account;

        dispatch(setAccounts(userAccountData));
        setSelectedAccountInfo(userAccountData.id, [userAccountData]);
        updateloginInfoAccount(userAccountData.id, authToken);
        if (storedRedirect) {
          localStorage.removeItem('redirectAfterLogin'); // Clear the stored URL
          window.location.href = storedRedirect; // Redirect the user
        } else {
          if (!checkUserIsNewEntrant(userData)) {
            navigate('/dashboard');
          } else {
            navigate('/new-entrant');
          }
        }
      } else if (storedRedirect) {
        const url = new URL(storedRedirect);
        let accountId = url.searchParams.get('account_id');
        accountId = decryptData(accountId, true);
        const account_Id = parseInt(accountId!, 10);
        console.log(account_Id);
        const isMatchingAccountId = userData?.user_access.some((data: any) => {
          return data.account_id === account_Id;
        });
        if (isMatchingAccountId) {
          setSelectedAccountInfo(account_Id, userData?.userAccounts);
          updateloginInfoAccount(account_Id, authToken);

          window.location.href = storedRedirect; // Redirect the user
        } else {
          navigate('/account-selection');
        }
      } else {
        // navigate to the AccountSelection component
        navigate('/account-selection');
      }
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = (): void => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };

  return (
    <CommonLayout title={metapageTitle}>
      <div className="pre-login-wrapper">
        <div className="pre-login-inner">
          <div className="left-col">
            <div className="left-inner">
              {showSuccessAlert && (
                <Alert
                  variant="outlined"
                  severity="success"
                  onClose={() => setForgotPasswordSuccess(false)}>
                  Your password has been reset. Please login using your new password.
                </Alert>
              )}

              <div className="logo-wrap">
                <Link to="/">
                  <LazyImage
                    src={`${S3PublicUrl}3lines-logo.svg`}
                    alt={'logo'}
                    rootMargin="0px 0px 200px 0px"
                  />
                </Link>
              </div>
              <div className="title-wrap">
                <h2>{t('user.loginToAccount')}</h2>
                <p>{t('user.welcomeBack')}</p>
              </div>
              {/* Error handling */}
              {formData.error && showAlert && (
                <Alert
                  variant="outlined"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleCloseAlert}>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }>
                  {formData.error}
                </Alert>
              )}
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-group">
                  <label className="form-label" htmlFor="email">
                    {t('user.email')}
                  </label>
                  <TextField
                    error={!!formData.emailError || !!formData.passwordError}
                    id="email"
                    variant="outlined"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter your email"
                    helperText={formData.emailError}
                    name="email"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <InfoOutlinedIcon className="message-ic" />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="password">
                    {t('user.password')}
                  </label>
                  <TextField
                    error={!!formData.emailError || !!formData.passwordError}
                    id="password"
                    variant="outlined"
                    name="password"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter your password"
                    helperText={formData.passwordError}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                {loading ? (
                  <div className="circular-progress-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <Button variant="contained" type="submit" className="btn primary-btn mb-3">
                      {t('user.signIn')}
                    </Button>
                  </>
                )}
                <div className="checkbox-link-wrap">
                  <Link to="/reset-password" className="primary-link">
                    {t('user.forgotPassword')}
                  </Link>{' '}
                </div>
              </form>
            </div>
          </div>
          <div className="right-image-col">
            <div className="right-inner">
              <div className="img-block">
                <LazyImage
                  src={`${S3PublicUrl}3Lines-login-web.webp`}
                  alt={'Login'}
                  rootMargin="0px 0px 200px 0px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default Login;
