import React from 'react';
import { Avatar, Button, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import default_user from '../../../assets/images/default_user.png';
import { AccountEditFormsProps } from './state';

const UserAccountDetailsForm: React.FC<AccountEditFormsProps> = ({
  personalInfo,
  handleChangeTimezone,
  handlePersonalInfoChange,
  handleCancelClick,
  handleSaveChanges,
  handleFileChange,
  removeHandle,
  previewURL,
  timezoneOptionsWithValueLabel,
  timezone,
  imageChanged,
  updateLogo,
  errors
}) => {
  return (
    <form>
      <div className="form-block">
        <div className="profile-image-block">
          <div className="image-block">
            {imageChanged && previewURL ? (
              <img src={previewURL} alt="Selected Profile Image" />
            ) : personalInfo.profile_image ? (
              <Avatar
                src={`${JSON.parse(personalInfo.profile_image).path}`}
                alt={personalInfo.first_name ? personalInfo.first_name : 'user'}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.style.display = 'none';
                  const nextElement = target.nextElementSibling as HTMLElement | null;
                  if (nextElement) {
                    nextElement.style.display = 'block';
                  }
                }}
              />
            ) : (
              <img src={default_user} alt="" />
            )}
          </div>
          <div className="content-block">
            <Button
              className="btn primary-btn btn-sm"
              onClick={() => document.getElementById('fileInput')?.click()} // Trigger the file input click event
            >
              Change
            </Button>
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              className="file-upload-input"
              onChange={handleFileChange}
              style={{ display: 'none' }} // Hide the input element
              title="file"
            />
            {(personalInfo.profile_image || updateLogo) && (
              <Link to="#" onClick={removeHandle} className="secondary-link" title="Remove">
                Remove
              </Link>
            )}
          </div>
        </div>
        {errors.logo && 
          <Typography className='form-error'>{errors.logo}</Typography>
        }
      </div>
      <div className="form-block">
        <div className="form-title">
          <h3>Personal Information</h3>
        </div>
        <div className="form-inner-wrap">
          <div className="custom-row has-two-col">
            <div className="custom-col">
              <div className="form-group">
                <label className="form-label">First Name</label>
                <TextField
                  error={!!errors.first_name}
                  variant="outlined"
                  className="form-control"
                  value={personalInfo.first_name}
                  onChange={handlePersonalInfoChange}
                  placeholder="First Name"
                  name="first_name"
                  helperText={errors.first_name}
                />
              </div>
            </div>
            <div className="custom-col">
              <div className="form-group">
                <label className="form-label">Last Name</label>
                <TextField
                  error={!!errors.last_name}
                  variant="outlined"
                  className="form-control"
                  placeholder="Last Name"
                  value={personalInfo.last_name}
                  onChange={handlePersonalInfoChange}
                  name="last_name"
                  helperText={errors.last_name}
                />
              </div>
            </div>
            <div className="custom-col">
              <div className="form-group">
                <label className="form-label">Email Address</label>
                <TextField
                  error={!!errors.email}
                  name="email"
                  variant="outlined"
                  className="form-control"
                  placeholder="Email Address"
                  value={personalInfo.email}
                  onChange={handlePersonalInfoChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <InfoOutlinedIcon className="message-ic" />
                      </InputAdornment>
                    )
                  }}
                  helperText={errors.email}
                />
              </div>
            </div>
            <div className="custom-col">
              <div className="form-group">
                <label className="form-label">Phone</label>
                <TextField
                  error={!!errors.phone}
                  name="phone"
                  variant="outlined"
                  className="form-control"
                  placeholder="Phone"
                  value={personalInfo.phone}
                  helperText={errors.phone}
                  onChange={handlePersonalInfoChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-block">
        <div className="form-title">
          <h3>System Settings</h3>
        </div>
        <div className="form-inner-wrap">
          <div className="custom-row">
            <div className="custom-col">
              <div className="form-group">
                <label className="form-label">Timezone</label>
                <Select
                  onChange={handleChangeTimezone}
                  displayEmpty
                  placeholder="Select Timezone"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={timezone} // Use an empty string as the default value
                  IconComponent={KeyboardArrowDownRoundedIcon}
                  className="custom-select"
                  startAdornment={
                    <InputAdornment position="start">
                      <AccessTimeRoundedIcon />
                    </InputAdornment>
                  }
                  MenuProps={{ className: 'custom-dropdown-menu' }}
                  renderValue={(selected) => {
                    if (selected === '') {
                      return <p className="dropdown-placeholder">Select Timezone</p>; // Render "Select Timezone" as placeholder
                    }
                    const selectedOption =
                      timezoneOptionsWithValueLabel && timezoneOptionsWithValueLabel.find((option) => option.offset === selected);
                    return selectedOption
                      ? `${selectedOption.name}`
                      : '';
                  }}>
                  {/* Default option */}
                  {timezoneOptionsWithValueLabel &&
                    timezoneOptionsWithValueLabel?.map((option) => (
                      <MenuItem
                        key={option?.offset}
                        value={option?.offset || ''} // Use optional chaining
                      >
                        {option?.name || ''}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>
            <div className="custom-col">
              <div className="form-group">
                {/* need to confirm for keeping the password here*/}
                {/* <label className="form-label">Password</label>
                    <TextField
                      variant="outlined"
                      className="form-control"
                      placeholder="Password"
                      type="password"
                      error
                      onChange={handleSystemSettingsChange}
                      value={systemSettings.password}
                    /> */}
                <Link className="primary-link blue-text" to="/user-reset-password">
                  Reset Password
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn-wrap">
        <Button onClick={handleCancelClick} className="btn border-btn btn-sm" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSaveChanges} className="btn primary-btn btn-sm">
          Save Changes
        </Button>
      </div>
    </form>
  );
};

export default UserAccountDetailsForm;
