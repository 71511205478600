import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { loginUser } from '../../../api/user/login';
import { LoginFormState } from './state';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { IconButton, InputAdornment, Alert } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useForgotPasswordContext } from './ForgotPasswordContext';
import { useDispatch } from 'react-redux'; // Import useDispatch
import { updateLogoutInfo } from '../../../api/dashboard/updateLogoutInfo';
import { Logout, useAuthToken } from '../../../utils/common/user';
import LazyImage from '../common/LazyLoad';
import { S3PublicUrl } from '../../../constants/constants';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Get the dispatch function
  const authToken = useAuthToken();

  const { forgotPasswordSuccess, setForgotPasswordSuccess } = useForgotPasswordContext();
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  let successAlertTimer: ReturnType<typeof setTimeout>;

  const [formData, setFormData] = useState<LoginFormState>({
    email: '',
    password: '',
    passwordError: '',
    error: '',
    emailError: ''
  });

  useEffect(() => {
    if (forgotPasswordSuccess) {
      setShowSuccessAlert(true);

      // Set a timer to close the alert after 5000 milliseconds (5 seconds)
      successAlertTimer = setTimeout(() => {
        setShowSuccessAlert(false);
        setForgotPasswordSuccess(false);
      }, 5000);
    }

    return () => {
      // Clear the timer when the component unmounts or when forgotPasswordSuccess changes
      clearTimeout(successAlertTimer);
    };
  }, [forgotPasswordSuccess, setForgotPasswordSuccess]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = (): boolean => {
    const { email, password } = formData;

    let isValid = true;

    if (!email) {
      setFormData((prevData) => ({
        ...prevData,
        emailError: t('user.emailRequired')
      }));
      isValid = false;
    } else if (email) {
      // Email pattern validation
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailPattern.test(email)) {
        setFormData((prevData) => ({
          ...prevData,
          emailError: t('user.enterValidEmail')
        }));

        isValid = false;
      } else {
        setFormData((prevData) => ({
          ...prevData,
          emailError: ''
        }));

        isValid = true;
      }
    }

    if (!password) {
      setFormData((prevData) => ({
        ...prevData,
        passwordError: t('user.passwordRequired')
      }));
      isValid = false;
    } else {
      setFormData((prevData) => ({
        ...prevData,
        passwordError: ''
      }));
    }

    return isValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    const errorMessage = await loginUser(dispatch, formData);

    if (errorMessage) {
      setFormData((prevData) => ({
        ...prevData,
        emailError: '',
        passwordError: '',
        error: errorMessage
      }));
    } else {
      const lastPageURL = localStorage.getItem('lastPageURL');
      if (lastPageURL) {
        navigate(lastPageURL);
      } else {
        navigate('/account-selection');
      }
    }
  };

  const handleLogout = async () => {
    try {
      await updateLogoutInfo(authToken);
    } catch (error) {
      console.error('Error updating:', error);
    }
    Logout();
    console.log('logout');
    navigate('/login');
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = (): void => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };

  return (
    <div className="pre-login-wrapper">
      <div className="pre-login-inner">
        <div className="left-col col">
          <div className="left-inner">
            {showSuccessAlert && (
              <Alert
                variant="outlined"
                severity="success"
                onClose={() => setForgotPasswordSuccess(false)}>
                Your password has been reset. Please login using your new password.
              </Alert>
            )}

            <div className="logo-wrap">
              <Link to="/">
                <LazyImage src={`${S3PublicUrl}3lines-logo.svg`} alt="logo" />
              </Link>
            </div>
            <div className="title-wrap">
              <h2 className="session-expire">{t('user.sessionExpire')}</h2>
              <p>{t('user.logBackIn')}</p>
            </div>
            {/* Error handling */}
            {formData.error && (
              <Alert variant="outlined" severity="error" onClose={() => {}}>
                <div>{formData.error}</div>
              </Alert>
            )}
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  {t('user.email')}
                </label>
                <TextField
                  error={!!formData.emailError || !!formData.passwordError}
                  id="email"
                  variant="outlined"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter your email"
                  helperText={formData.emailError}
                  name="email"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <InfoOutlinedIcon className="message-ic" />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="password">
                  {t('user.password')}
                </label>
                <TextField
                  error={!!formData.emailError || !!formData.passwordError}
                  id="password"
                  variant="outlined"
                  name="password"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter your password"
                  helperText={formData.passwordError}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <Button variant="contained" type="submit" className="btn primary-btn mb-3">
                {t('user.signIn')}
              </Button>
              <div className="checkbox-link-wrap">
                <Link to="/reset-password" className="primary-link">
                  {t('user.forgotPassword')}
                </Link>{' '}
              </div>
            </form>
            <button className="btn primary-btn mb-3 logout-btn" onClick={handleLogout}>
              Log out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
